import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login";
import PasswordRecovery from "../views/PasswordRecovery";
import UserSignUp from "../views/UserSignUp";
import TermsOfUse from "../views/TermsOfUse";
import UserProfile from "../views/UserProfile";
import Home from "../views/Home";
import QuoteList from "../views/QuoteList";
import QuestionList from "../views/QuestionList";
import QuoteDetails from "../views/QuoteDetails";
import ProductService from "../views/ProductService";
import ProductServiceCrud from "../views/ProductServiceCrud";
import HowPhotographProduct from "../views/HowPhotographProduct";
import Support from "../views/Support";
import authentication from "@/util/authentication";

const routes = [
  {
    path: "/",
    alias: ["/login"],
    name: "Login",
    component: Login,
    meta: {
      isPublic: true,
      title: 'Login'
    },
  },
  {
    path: "/recuperar-senha",
    name: "PasswordRecovery",
    component: PasswordRecovery,
    meta: {
      isPublic: true,
      title: 'Recuperação de senha'
    },
  },
  {
    path: "/cadastro",
    name: "UserSignUp",
    component: UserSignUp,
    meta: {
      isPublic: true,
      title: 'Cadastro'
    },
  },
  {
    path: "/termos-de-uso",
    name: "TermsOfUse",
    component: TermsOfUse,
    meta: {
      isPublic: true,
      title: 'Termos de uso'
    },
  },
  {
    path: "/meus-dados",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      isPublic: false,
      title: 'Meus dados'
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      isPublic: false,
      title: 'Home'
    },
  },
  {
    path: "/orcamentos",
    name: "QuoteList",
    component: QuoteList,
    meta: {
      isPublic: false,
      title: 'Orçamentos'
    },
  },
  {
    path: "/perguntas",
    name: "QuestionList",
    component: QuestionList,
    meta: {
      isPublic: false,
      title: 'Perguntas'
    },
  },
  {
    path: "/detalhes-orcamento/:id",
    name: "QuoteDetails",
    component: QuoteDetails,
    meta: {
      isPublic: false,
      title: 'Detalhes orçamento'
    },
  },
  {
    path: "/produtos-servicos",
    name: "ProductService",
    component: ProductService,
    meta: {
      isPublic: false,
      title: 'Produtos Serviços'
    },
  },
  {
    path: "/produtos-servicos/:id",
    name: "ProductServiceCrud",
    component: ProductServiceCrud,
    meta: {
      isPublic: false,
      title: 'Produtos Serviços Detalhes'
    },
  },
  {
    path: "/como-fotografar-produtos",
    name: "HowPhotographProduct",
    component: HowPhotographProduct,
    meta: {
      isPublic: false,
      title: 'Como fotografar'
    },
  },
  {
    path: "/suporte",
    name: "Support",
    component: Support,
    meta: {
      isPublic: true,
      title: 'Suporte'
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from) {
    if (from.path != to.path) {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach(async (to, from) => {
  if (!authentication.isLoggedIn() && !to.meta.isPublic) {
    return { name: "Login" };
  }
});

router.afterEach((to) => {
  document.title = `Festeje ai | ${to.meta.title}` || "Festeje ai";
  if (process.env.NODE_ENV !== "development") {
    gtag("config", window.GA_TRACKING_ID, {
      page_path: to.fullPath,
      send_page_view: true
    });
    fbq("track", "PageView");
  }
});

export default router;
