<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import authentication from "../util/authentication";
import LoginIcon from "vue-material-design-icons/LoginVariant";

const router = useRouter();
const showMenu = ref(false);

const openMenu = () => {
  showMenu.value = true;
};
const closeMenu = () => {
  showMenu.value = false;
};
const goHome = () => {
  if (router.currentRoute.value.name !== "Home") router.push({ path: "/home" });
};
const logout = () => {
  authentication.logout();
};

const userLoggedIn = computed(() => {
  return authentication.isLoggedIn();
});
</script>

<template>
  <header>
    <div class="container">
      <img
        class="logo"
        src="../assets/images/logo/logo-white-yellow.png"
        alt="Nutrixy Logo"
        @click="goHome"
      />
      <div class="holder">
        <ul v-show="!userLoggedIn" class="navbar tablet-hidden mobile-hidden">
          <li>
            <router-link to="/login">
              <LoginIcon fillColor="#eee" />
              <p>Fazer login</p>
            </router-link>
          </li>
        </ul>
        <ul v-show="userLoggedIn" class="navbar tablet-hidden mobile-hidden">
          <li>
            <router-link
              :class="{
                'navbar-active': router.currentRoute.value.name == 'Home',
              }"
              class="navbar-hover"
              to="/home"
            >
              <p>Início</p>
            </router-link>
          </li>
          <li>
            <router-link
              :class="{
                'navbar-active':
                  router.currentRoute.value.name == 'ProductService',
              }"
              class="navbar-hover pointer"
              to="/produtos-servicos"
            >
              <p>Produtos/Serviços</p>
            </router-link>
          </li>
          <li>
            <router-link
              :class="{
                'navbar-active':
                  router.currentRoute.value.name == 'QuestionList',
              }"
              class="navbar-hover pointer"
              to="/perguntas"
            >
              <p>Perguntas de clientes</p>
            </router-link>
          </li>
          <li>
            <router-link
              :class="{
                'navbar-active': router.currentRoute.value.name == 'QuoteList',
              }"
              class="navbar-hover pointer"
              to="/orcamentos"
            >
              <p>Orçamentos</p>
            </router-link>
          </li>
          <li>
            <router-link
              :class="{
                'navbar-active':
                  router.currentRoute.value.name == 'UserProfile',
              }"
              class="navbar-hover"
              to="/meus-dados"
            >
              <p>Meus dados</p>
            </router-link>
          </li>
          <li>
            <router-link
              :class="{
                'navbar-active': router.currentRoute.value.name == 'Support',
              }"
              class="navbar-hover"
              to="/suporte"
            >
              <p>Suporte</p>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'HowPhotographProduct' }" class="pointer">
              <p class="special-button">Destaque seu negócio</p>
            </router-link>
          </li>
          <li>
            <a @click="logout" class="pointer navbar-hover">
              <p>Sair</p>
            </a>
          </li>
        </ul>

        <div @click.stop="openMenu" class="menu-button desktop-hidden">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>

      <transition
        enter-to-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <nav v-click-outside="closeMenu" v-show="showMenu">
          <ul v-show="!userLoggedIn">
            <li>
              <router-link to="/login">
                <LoginIcon fillColor="var(--primary)" />
                <p>Fazer login</p>
              </router-link>
            </li>
          </ul>
          <ul v-show="userLoggedIn">
            <li>
              <router-link
                to="/home"
                class="create-account"
                :class="{
                  'navbar-active-mobile':
                    router.currentRoute.value.name == 'Home',
                }"
              >
                <p>Início</p>
              </router-link>
            </li>
            <li>
              <router-link
                class="create-account"
                :class="{
                  'navbar-active-mobile':
                    router.currentRoute.value.name == 'ProductService',
                }"
                to="/produtos-servicos"
              >
                <p>Produtos/Serviços</p>
              </router-link>
            </li>
            <li>
              <router-link
                class="create-account"
                :class="{
                  'navbar-active-mobile':
                    router.currentRoute.value.name == 'QuestionList',
                }"
                to="/perguntas"
              >
                <p>Perguntas de clientes</p>
              </router-link>
            </li>
            <li>
              <router-link
                class="create-account"
                :class="{
                  'navbar-active-mobile':
                    router.currentRoute.value.name == 'QuoteList',
                }"
                to="/orcamentos"
              >
                <p>Orçamentos</p>
              </router-link>
            </li>
            <li>
              <router-link
                to="/meus-dados"
                class="create-account"
                :class="{
                  'navbar-active-mobile':
                    router.currentRoute.value.name == 'UserProfile',
                }"
              >
                <p>Meus dados</p>
              </router-link>
            </li>
            <li>
              <router-link
                to="/suporte"
                class="create-account"
                :class="{
                  'navbar-active-mobile':
                    router.currentRoute.value.name == 'Support',
                }"
              >
                <p>Suporte</p>
              </router-link>
            </li>
            <li>
              <router-link
                class="create-account"
                :class="{
                  'navbar-active-mobile':
                    router.currentRoute.value.name == 'HowPhotographProduct',
                }"
                to="/como-fotografar-produtos"
              >
                <p>Destaque seu negócio</p>
              </router-link>
            </li>
            <li>
              <a @click="logout" class="create-account">
                <p>Sair</p>
              </a>
            </li>
          </ul>
        </nav>
      </transition>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  position: relative;
  width: 100vw;
  height: 165px;
  background: linear-gradient(89.27deg, #191c2d 14.86%, #0f111d 98.93%);
  left: 0;
  top: 0;
  .container {
    position: relative;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
      position: relative;
      height: 55%;
      cursor: pointer;
      @media only screen and (max-width: 1300px) {
        height: 40%;
      }
    }
  }
}

.holder {
  position: relative;
  margin-left: auto;
  .user-item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    a {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.75rem;
      letter-spacing: 1px;
    }
  }
}
.navbar {
  position: relative;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 1158px) and (max-width: 1300px) {
    gap: 0.5rem;
  }
  li {
    position: relative;
    a {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        color: #eee;
        margin-left: 5px;
      }
      .special-button {
        color: #050a0e;
        background-color: #f9cc00;
        border-radius: 5px;
        height: 30px;
        align-items: center;
        display: flex;
        padding: 10px;
      }
    }
  }
}

.menu-button {
  position: relative;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  .line {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #f1f1f1;
  }
}

nav {
  position: absolute;
  top: 62px;
  right: 0;
  width: 240px;
  padding: 15px !important;
  border-radius: 0.7em;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  z-index: 999;

  ul li {
    position: relative;
    width: 100%;
    height: 48px;

    &:not(:last-child) {
      border-bottom: 1px solid #00000015;
    }

    a {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        font-family: fontLight;
        font-size: 1.15em;
        margin-left: 15px !important;
        color: var(--primary);
      }
    }
  }
}

.navbar-hover {
  p {
    transition: color 0.4s ease;
  }
  &:hover {
    p {
      color: var(--secondary) !important;
    }
  }
}

.navbar-active p {
  padding: 5px 15px;
  border-radius: 6px;
  border: 1px solid var(--secondary);
  color: var(--secondary) !important;
}

.navbar-active-mobile p {
  color: var(--secondary) !important;
}
</style>
