<script setup>
import Header from "@/components/Header";
import Page from "@/baseComponents/Page";
import Card from "@/baseComponents/Card";
import FaceIcon from "vue-material-design-icons/FaceAgent";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import EmailIcon from "vue-material-design-icons/EmailOutline.vue";
import Facebook from "vue-material-design-icons/Facebook.vue";
import Instagram from "vue-material-design-icons/Instagram.vue";
import Linkedin from "vue-material-design-icons/Linkedin.vue";

const openWhats = () => {
  window.innerWidth > 719
    ? window.open(
        "https://web.whatsapp.com/send?phone=5511917070303&text=Ol%C3%A1%2C%20estou%20no%20Festeje%20Aí%20e%20gostaria%20de%20informa%C3%A7%C3%B5es"
      )
    : window.open(
        "https://api.whatsapp.com/send?phone=5511917070303&text=Ol%C3%A1%2C%20estou%20no%20Festeje%20Aí%20e%20gostaria%20de%20informa%C3%A7%C3%B5es"
      );
};
</script>
<template>
  <Header />
  <Page>
    <Card class="card-holder">
      <div class="card-header">
        <div class="item mobile-hidden">
          <FaceIcon :size="30" fillColor="var(--primary)" />
        </div>
        <span>Suporte ao usuário</span>
      </div>
      <div class="divider mobile-hidden"></div>
      <div>
        <p class="title">Precisa de ajuda com o nosso aplicativo?</p>
        <p class="subtitle mt-1">
          Estamos aqui para garantir que sua experiência seja tranquila e
          satisfatória. Se encontrar qualquer dificuldade ou tiver dúvidas,
          nossa equipe está pronta para oferecer o suporte necessário.
        </p>

        <div class="flex-column flex-center mt-1">
          <span class="info-title mt-2">Em caso de dúvida, contate via</span>
          <a class="link-holder" href="mailto:marketing@festeje.ai">
            <EmailIcon :size="26" />
            marketing@festeje.ai
          </a>
          <div @click="openWhats" class="link-holder pointer">
            <Whatsapp :size="26" />
            (11) 91707-0303
          </div>
        </div>

        <div class="flex-column flex-center mt-1">
          <span class="info-title mt-2">Acesse nossas redes sociais</span>
          <div class="social-media mt-1">
            <a
              href="https://www.facebook.com/festejeai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook :size="26" />
            </a>
            <a
              href="https://www.linkedin.com/company/festejeai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin :size="26" />
            </a>
            <a
              href="https://www.instagram.com/festeje.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram :size="26" />
            </a>
          </div>
        </div>

        <!-- <div class="img-holder mt-3">
          <img src="../assets/images/support.png" alt="Suporte" />
        </div> -->
      </div>
    </Card>
  </Page>
</template>
<style lang="scss" scoped>
.divider {
  margin: 0 auto 2rem 0;
}
.title {
  font-size: 1.15em;
  text-align: center;
  font-family: fontBold;
}
.subtitle {
  font-size: 1.1em;
  text-align: center;
}

.link-holder {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  font-size: 1.05em;
}

.info-title {
  font-size: 1.1em;
  letter-spacing: 1px;
}

.social-media {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.img-holder {
  position: relative;
  width: 100%;
  height: 130px;
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  background-color: #fff;
  @media only screen and (max-width: 1159px) {
    width: 100%;
  }
  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    bottom: 24px;
  }
}
</style>
