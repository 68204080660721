<script setup>
import { ref, watch, inject } from "vue";

import http from "@/http";

import Card from "@/baseComponents/Card";

import CommentIcon from "vue-material-design-icons/CommentQuestion";
import SendIcon from "vue-material-design-icons/Send";
import AccountIcon from "vue-material-design-icons/AccountCircle";
import ArrowIcon from "vue-material-design-icons/ArrowRightBottom";
import CheckIcon from "vue-material-design-icons/Check";
import WarningIcon from "vue-material-design-icons/AlertCircleOutline";
import ErrorIcon from "vue-material-design-icons/Close";

const $formatDate = inject("$formatDate");

const props = defineProps({
  messagesData: {
    type: Array,
    default: () => {
      return [];
    },
  },
  quoteStatus: {
    type: String,
    default: "",
  },
  eventId: {
    type: String,
    default: "",
  },
  productId: {
    type: String,
    default: "",
  },
  userId: {
    type: String,
    default: "",
  },
});

watch(
  () => props.messagesData,
  (value) => {
    const newMsgs = value.map((msg) => {
      msg.sent = true;
      return msg;
    });
    messages.value = newMsgs;
    scrollMessages();
  }
);

const messages = ref([]);
const newMessage = ref("");
const loading = ref(false);

const sendMessage = () => {
  if (!newMessage.value) return;
  const newMsg = {
    eventId: props.eventId,
    productId: props.productId,
    userId: props.userId,
    description: newMessage.value,
    contractorName: "",
    sent: false,
    from: "Fornecedor",
    timestamp: new Date(),
  };
  newMessage.value = "";
  messages.value.push(newMsg);
  scrollMessages();
  http
    .post("message", newMsg)
    .then((response) => {
      const lastMsg = messages.value[messages.value.length - 1];
      lastMsg.timestamp = response.data?.timestamp;
      lastMsg.sent = true;
      lastMsg.contractorName = response.data?.contractorName;
    })
    .catch((err) => {
      const lastMsg = messages.value[messages.value.length - 1];
      lastMsg.error = "Não foi possível enviar esta mensagem.";
      console.log(lastMsg.error);
    });
};

const scrollMessages = () => {
  const messagesHolder = document.querySelector("#messagesHolder");
  setTimeout(() => {
    messagesHolder.scroll({
      top: messagesHolder.scrollHeight,
      behavior: "smooth",
    });
  }, 50);
};
</script>

<template>
  <Card class="animated zoomIn">
    <div class="card-holder">
      <div class="card-header">
        <div class="item mobile-hidden">
          <CommentIcon :size="30" fillColor="var(--primary)" />
        </div>
        <span>Mensagens trocadas neste orçamento</span>
      </div>
      <div id="messagesHolder" class="messages-holder">
        <div v-show="loading" class="flex flex-center mt-3">
          <div class="loading"></div>
        </div>
        <div
          v-for="(msg, index) in messages"
          :key="`msg${index}`"
          class="message animated zoomIn"
          :class="{
            response: msg.from === 'Fornecedor',
            blocked: msg.status === 'Bloqueada',
            blocked: msg.error != undefined,
          }"
        >
          <CheckIcon
            v-if="msg.sent"
            class="check-icon animated zoomIn"
            fillColor="var(--green1)"
            :size="18"
          />
          <ErrorIcon
            v-if="msg.error"
            class="check-icon animated zoomIn"
            fillColor="var(--red1)"
            :size="18"
          />
          <div class="header">
            <AccountIcon
              :fillColor="msg.from === 'Fornecedor' ? '#71b2ed' : '#545454'"
              :size="32"
            />
            <p v-if="msg.from === 'Fornecedor'">EU:</p>
            <p v-else>{{ msg.userName.split(" ").shift() }}:</p>
            <div class="date">{{ $formatDate(msg.timestamp) }}</div>
          </div>
          <div class="content">
            <div class="icon">
              <ArrowIcon
                :fillColor="msg.from === 'Fornecedor' ? '#71b2ed' : '#545454'"
              />
            </div>
            <div v-if="msg.status === 'Bloqueada'" class="blocked-msg">
              <WarningIcon fillColor="#ED193F" :size="30" />
              <div class="msg">
                Mensagem bloqueada por violar as diretrizes da plataforma.
              </div>
            </div>
            <div v-else class="body">{{ msg.description }}</div>
          </div>
          <div v-if="msg.error" class="msg-error">{{ msg.error }}</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="message-input-holder">
        <div
          v-show="quoteStatus !== 'Reprovado' && quoteStatus !== 'Cancelado'"
          class="input-with-button"
        >
          <input
            v-model="newMessage"
            type="text"
            placeholder="Nova mensagem..."
            @keypress.enter="sendMessage"
          />
          <div @click="sendMessage" class="input-btn">
            <SendIcon fillColor="var(--primary)" />
          </div>
        </div>
        <p
          v-if="quoteStatus !== 'Reprovado' && quoteStatus !== 'Cancelado'"
          class="warning"
        >
          <b>Atenção: </b>as mensagens não podem ser apagadas.
        </p>
        <p v-else class="warning">
          Não é possível enviar mensagem quando o status do orçamento está
          <b>{{ quoteStatus }}</b>
        </p>
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.messages-holder {
  position: relative;
  display: flex;
  overflow-y: auto;
  padding: 10px 1rem 0 1rem;
  flex-direction: column;
  gap: 1rem;
  height: 50vh;
  &::-webkit-scrollbar {
    width: 0.2em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    outline: 1px solid slategrey;
  }
  .message {
    position: relative;
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #dadada;
    border-radius: 9px;
    &.response {
      border-color: #3585fd;
      .header p {
        color: #3585fd;
      }
      .content .body {
        color: #0055d4;
      }
      .date {
        color: #3585fd;
      }
    }
    &.blocked {
      border-color: #ed193f;
      .blocked-msg {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        .msg {
          color: #ed193f;
        }
      }
    }
    .header {
      position: relative;
      display: grid;
      grid-template-columns: 32px 1fr 1fr;
      align-items: center;
      gap: 0.55rem;
      p {
        font-family: fontMedium;
      }
      .date {
        position: relative;
        font-family: fontLight;
        margin-left: auto;
      }
    }
    .content {
      position: relative;
      display: grid;
      grid-template-columns: 32px 1fr;
      align-items: flex-start;
      gap: 0.55rem;
      .icon {
        position: relative;
        left: 8px;
      }
      .body {
        font-family: fontLight;
      }
    }
    .check-icon {
      position: absolute;
      right: 0.35rem;
      top: calc(100% - 22px);
      pointer-events: none;
    }
    .msg-error {
      color: #ed193f;
    }
  }
}

.message-input-holder {
  padding: 0 1rem 0 1rem;
  padding-top: 0.3rem;
  .warning {
    font-size: 12px;
    color: #fa5875;
    margin-top: 10px;
    font-style: italic;
  }
}

.input-with-button {
  position: relative;
  width: 100%;
  height: 44px;
  margin: 5px 0 0 0;
  background-color: #eaeaea;
  border-radius: 8px;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-right: 40px;
    top: 0;
    left: 0;
    color: var(--primary);
    background-color: transparent;
    padding-left: 0.75rem;
    &::placeholder {
      color: var(--dark4);
    }
  }
  .input-btn {
    position: relative;
    width: 45px;
    height: 100%;
    margin: 0 0 0 auto;
    border-radius: 5px;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
}
</style>
