<script setup>
import { ref, inject } from "vue";

import http from "@/http";

import Header from "@/components/Header";
import Page from "@/baseComponents/Page";
import Messages from "@/components/Messages";
import BudgetList from "@/components/home/BudgetList";
import QuestionList from "@/components/home/QuestionList";

const swal = inject("$swal");

const loadingQuestion = ref(true);
const questions = ref([]);
const showChat = ref(false);
const messageSent = ref(false);
const selectedEventId = ref("");
const selectedProductId = ref("");
const selectedUserId = ref("");
const selectedQuestion = ref("");

http
  .get("message/list/conversation?page=1&take=10&orderBy=timestamp")
  .then((response) => {
    questions.value = response?.data?.list;
  })
  .catch((err) => {
    console.log(err);
    fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
  })
  .finally(() => {
    loadingQuestion.value = false;
  });

const openChat = (eventId, productId, userId, questionIndex) => {
  showChat.value = true;
  selectedEventId.value = eventId;
  selectedProductId.value = productId;
  selectedUserId.value = userId;
  selectedQuestion.value = questionIndex;
};

const confirmMessageSent = () => {
  messageSent.value = true;
};

const closeChat = () => {
  showChat.value = false;
  if (messageSent.value) {
    questions.value.splice(selectedQuestion.value, 1);
    messageSent.value = false;
  }
};

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    text: msg,
    showConfirmButton: true,
  });
};
</script>

<template>
  <Header />
  <Page>
    <div class="flex flex-column">
      <BudgetList class="animated zoomIn" style="animation-delay: 200ms" />

      <QuestionList
        class="animated zoomIn"
        style="animation-delay: 400ms"
        :loadingQuestion="loadingQuestion"
        :questions="questions"
        @openChat="openChat"
      />

      <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div @click="closeChat" v-show="showChat" class="bg-cover"></div>
      </transition>

      <Messages
        @close="closeChat"
        @lastMessageSent="confirmMessageSent"
        :show="showChat"
        :eventId="selectedEventId"
        :productId="selectedProductId"
        :userId="selectedUserId"
      />
    </div>
  </Page>
</template>

<style lang="scss" scoped>
.flex {
  gap: 3rem;
}
</style>
